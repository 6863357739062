
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;600&display=swap');

html,
body {
  height: 100%;
  font-family: 'Inter', sans-serif;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  /*align-items: center;
  /*padding-top: 40px;
  padding-bottom: 40px;*/
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 100px auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}